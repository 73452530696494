import React from 'react';
import { Star, Globe } from 'lucide-react';

const EnhancedRecipeViewer = ({
                                  recipe,
                                  handleFavoriteClick,
                                  isFavorited,
                                  useMetric,
                                  setUseMetric,
                                  RecipeIngredients,
                                  RecipeInstructions,
                                  convertToMetric,
                                  imagesDomainPath
                              }) => {
    const getDomain = (url) => {
        try {
            const domain = new URL(url).hostname.replace('www.', '');
            return domain;
        } catch {
            return '';
        }
    };

    return (
        <div className="w-full space-y-6">
            {/* Top Row: Adaptive Title and Image */}
            <div className={`grid ${recipe.ogImage ? 'min-[850px]:grid-cols-2' : ''} gap-8`}>
                {/* Title and Metadata */}
                <div className="flex flex-col justify-center items-center min-h-[240px] text-center">
                    <div className="space-y-4">
                        <div className="flex items-center justify-center gap-3">
                            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                                {recipe.title}
                            </h1>
                            <button
                                onClick={handleFavoriteClick}
                                className="print:hidden p-1.5 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800
                  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                  dark:focus:ring-offset-gray-900"
                                aria-label={isFavorited ? "Remove from favorites" : "Add to favorites"}
                            >
                                {isFavorited ? (
                                    <Star className="w-6 h-6 text-yellow-500 fill-yellow-500" />
                                ) : (
                                    <Star className="w-6 h-6 text-gray-400 dark:text-gray-500 hover:text-yellow-500" />
                                )}
                            </button>
                        </div>

                        <div className="flex flex-col items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                            {recipe.servings && (
                                <div className="flex items-center gap-1">
                                    <span>Serves {recipe.servings}</span>
                                </div>
                            )}
                            {recipe.url && (
                                <div className="flex items-center gap-1.5">
                                    <Globe className="w-4 h-4" />
                                    <span>{getDomain(recipe.url)}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Image */}
                {recipe.ogImage && (
                    <div className="w-full print:hidden">
                        <img
                            src={`${imagesDomainPath}/${recipe.ogImage.replace(/\\/g, '/')}`}
                            alt={recipe.title}
                            className="w-full max-h-[500px] object-cover rounded-xl shadow-sm"
                            loading="lazy"
                        />
                    </div>
                )}

            </div>

            {/* Bottom Row: Ingredients and Instructions */}
            <div className="grid min-[850px]:grid-cols-2 gap-8">
                {/* Ingredients */}
                <div className="bg-gray-50 dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
                    <div className="mb-4 print:hidden">
                        <label className="inline-flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                            <input
                                type="checkbox"
                                className="form-checkbox w-4 h-4 text-blue-500 dark:text-blue-400
                  rounded border-gray-300 dark:border-gray-600"
                                checked={useMetric}
                                onChange={() => setUseMetric(!useMetric)}
                            />
                            <span>Show metric measurements</span>
                        </label>
                    </div>
                    <RecipeIngredients
                        ingredients={recipe.ingredients}
                        useMetric={useMetric}
                        convertToMetric={convertToMetric}
                    />
                </div>

                {/* Instructions */}
                <div className="bg-gray-50 dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
                    <RecipeInstructions recipe={recipe} />
                </div>
            </div>
        </div>
    );
};

export default EnhancedRecipeViewer;