import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import RecipeExtractor from './RecipeExtractor';
import ShoppingList from "./components/shoppingList";
import RecipesPage from "./components/recipesPage";
import MainLayout from "./components/mainLayout";
import {UserProvider} from './components/UserContext';

// Privacy Policy Component
const PrivacyPolicy = () => (
    <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors">
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Privacy Policy for RecipeExtractor</h1>
            <p className="mb-4 text-gray-700 dark:text-gray-300">At RecipeExtractor, we are committed to protecting your privacy. This privacy policy outlines how we collect, use, and protect your personal information when you interact with our services.</p>

            <h2 className="text-2xl font-bold mt-6 mb-3 text-gray-900 dark:text-white">Information We Collect</h2>
            <p className="mb-2 text-gray-700 dark:text-gray-300">We collect the following personal information:</p>
            <ul className="list-disc ml-6 mb-4 text-gray-700 dark:text-gray-300">
                <li className="mb-2"><strong className="text-gray-900 dark:text-white">Email address</strong>: Collected when you voluntarily provide it.</li>
                <li className="mb-2"><strong className="text-gray-900 dark:text-white">Analytics data</strong>: We use cookies to gather non-personally identifiable information to analyze site usage and improve our services.</li>
            </ul>

            <h2 className="text-2xl font-bold mt-6 mb-3 text-gray-900 dark:text-white">How We Use Your Information</h2>
            <p className="mb-2 text-gray-700 dark:text-gray-300">We use the collected data to:</p>
            <ul className="list-disc ml-6 mb-4 text-gray-700 dark:text-gray-300">
                <li>Communicate with you through the email address you provide.</li>
                <li>Analyze site usage through analytics tools to improve our services and user experience.</li>
            </ul>

            <h2 className="text-2xl font-bold mt-6 mb-3 text-gray-900 dark:text-white">Data Sharing</h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">We do not share your personal information with third parties, except for the analytics data collected by third-party services we use to analyze site usage.</p>

            <h2 className="text-2xl font-bold mt-6 mb-3 text-gray-900 dark:text-white">Data Security</h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">We are committed to ensuring that your data is secure. We take reasonable precautions to safeguard the personal information we collect.</p>

            <h2 className="text-2xl font-bold mt-6 mb-3 text-gray-900 dark:text-white">Contact Information</h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">If you have any questions about this privacy policy, please contact us at: support@recipeextractor.com.</p>
        </div>
    </div>
);

// Cookie Policy Component
const CookiePolicy = () => (
    <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors">
        <div className="container mx-auto px-4 py-8 max-w-3xl">
            <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Cookie Policy for RecipeExtractor</h1>
            <p className="mb-4 text-gray-700 dark:text-gray-300">This cookie policy explains what cookies are, how we use them, and your options regarding cookies when you use RecipeExtractor.</p>

            <h2 className="text-2xl font-bold mt-6 mb-3 text-gray-900 dark:text-white">What Are Cookies?</h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">Cookies are small text files placed on your device by a website. They are used to store information such as your preferences or analytics data.</p>

            <h2 className="text-2xl font-bold mt-6 mb-3 text-gray-900 dark:text-white">How We Use Cookies</h2>
            <p className="mb-2 text-gray-700 dark:text-gray-300">We use cookies to:</p>
            <ul className="list-disc ml-6 mb-4 text-gray-700 dark:text-gray-300">
                <li><strong className="text-gray-900 dark:text-white">Analyze site usage</strong>: We use analytics cookies to track how users interact with the site to improve user experience and functionality.</li>
            </ul>

            <h2 className="text-2xl font-bold mt-6 mb-3 text-gray-900 dark:text-white">Types of Cookies We Use</h2>
            <ul className="list-disc ml-6 mb-4 text-gray-700 dark:text-gray-300">
                <li><strong className="text-gray-900 dark:text-white">Analytics Cookies</strong>: These cookies help us understand how users engage with the site. The information collected is anonymous and is only used to improve our services.</li>
            </ul>

            <h2 className="text-2xl font-bold mt-6 mb-3 text-gray-900 dark:text-white">Managing Cookies</h2>
            <p className="mb-4 text-gray-700 dark:text-gray-300">You can choose to accept or decline cookies. When you visit our website, you will be prompted to consent to the use of analytics cookies. If you do not wish to allow cookies, you can refuse consent when prompted, or you can adjust your browser settings to block cookies.</p>
        </div>
    </div>
);




const App = () => {
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    if (!GOOGLE_CLIENT_ID) {
        console.error('Google Client ID not found in environment variables');
        return <div className="text-red-600 dark:text-red-400">Error: Google Client ID not configured</div>;
    }

    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <UserProvider>
                <Router>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <MainLayout>
                                    <RecipeExtractor />
                                </MainLayout>
                            }
                        />
                        <Route
                            path="/recipe/:recipeId"
                            element={
                                <MainLayout>
                                    <RecipeExtractor />
                                </MainLayout>
                            }
                        />
                        <Route
                            path="/shopping-list"
                            element={
                                <PrivateRoute>
                                    <MainLayout>
                                        <ShoppingList />
                                    </MainLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/recipes"
                            element={
                                <PrivateRoute>
                                    <MainLayout>
                                        <RecipesPage />
                                    </MainLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/privacy-policy"
                            element={
                                <MainLayout>
                                    <PrivacyPolicy />
                                </MainLayout>
                            }
                        />
                        <Route
                            path="/cookie-policy"
                            element={
                                <MainLayout>
                                    <CookiePolicy />
                                </MainLayout>
                            }
                        />
                    </Routes>
                </Router>
            </UserProvider>
        </GoogleOAuthProvider>
    );
};

const PrivateRoute = ({ children }) => {
    const [user] = useState(localStorage.getItem('recipeToken'));
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/', { replace: true });
        }
    }, [user, navigate]);

    return user ? children : null;
};

export default App;