import React from 'react';
import { Check, ChevronDown, ChevronUp } from 'lucide-react';

export const RecipeInstructions = ({ recipe }) => {
    const [completedSteps, setCompletedSteps] = React.useState(new Set());
    const [expandedCategories, setExpandedCategories] = React.useState(new Set());
    const [activeStep, setActiveStep] = React.useState(null);

    const { instructions, category, ingredients } = React.useMemo(() => ({
        instructions: recipe.instructions || [],
        category: recipe.category?.category || [],
        ingredients: recipe.ingredients || []
    }), [recipe]);

    const processedInstructions = React.useMemo(() => {
        if (!category || category.length === 0) {
            return {
                type: 'legacy',
                data: [['Main Steps', instructions.map((step, index) => ({
                    step,
                    index,
                    category: 'Main Steps'
                }))]]
            };
        }

        const grouped = category.reduce((acc, instruction) => {
            const categoryName = instruction.category || 'Main Steps';
            if (!acc[categoryName]) {
                acc[categoryName] = [];
            }
            const stepIndex = parseInt(instruction.step) - 1;
            acc[categoryName].push({
                step: instructions[stepIndex],
                index: stepIndex
            });
            return acc;
        }, {});

        return {
            type: 'categorized',
            data: Object.entries(grouped)
        };
    }, [instructions, category]);

    const totalSteps = React.useMemo(() => {
        return instructions?.length || 0;
    }, [instructions]);

    const shouldShowIngredients = totalSteps <= 20;

    const toggleStep = (index) => {
        const newCompleted = new Set(completedSteps);
        if (newCompleted.has(index)) {
            newCompleted.delete(index);
        } else {
            newCompleted.add(index);
            for (let i = 0; i < index; i++) {
                newCompleted.add(i);
            }
        }
        setCompletedSteps(newCompleted);
    };

    const toggleCategory = (category) => {
        const newExpanded = new Set(expandedCategories);
        if (newExpanded.has(category)) {
            newExpanded.delete(category);
        } else {
            newExpanded.add(category);
        }
        setExpandedCategories(newExpanded);
    };

    const getStepNumbering = (index) => {
        const number = index + 1;
        return number < 10 ? `0${number}` : number;
    };

    const getStepIngredients = (stepNumber) => {
        if (!shouldShowIngredients) return [];
        return ingredients?.filter(ing => {
            const steps = Array.isArray(ing.step) ? ing.step : [ing.step];
            return steps.includes((stepNumber + 1).toString());
        }) || [];
    };

    const formatIngredient = (ing) => {
        const parts = [ing.ingredient];
        //if (ing.special) parts.push(`(${ing.special})`);
        return parts.filter(Boolean).join(' ');
    };

    const renderIngredientBadges = (stepIngredients) => {
        if (!stepIngredients.length) return null;

        return (
            <div className="mt-3 flex flex-wrap gap-2">
                {stepIngredients.map((ing, idx) => (
                    <div
                        key={idx}
                        className="inline-flex items-center px-2.5 py-1 rounded-md bg-gray-50 dark:bg-gray-700/50 border border-gray-200 dark:border-gray-600"
                    >
                        <span className="text-sm text-gray-600 dark:text-gray-300">
                            {formatIngredient(ing)}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    const renderInstructionStep = (step, index) => {
        const stepIngredients = getStepIngredients(index);
        return (
            <div
                key={index}
                className={`relative ${
                    activeStep === index
                        ? 'bg-gray-50 dark:bg-gray-700/50'
                        : 'hover:bg-gray-50 dark:hover:bg-gray-700/50'
                } transition-colors duration-200`}
            >
                <div className={`p-4 ${processedInstructions.type === 'categorized' ? 'pl-8' : ''}`}>
                    <div className="flex gap-4">
                        <div className="flex-shrink-0">
                            <button
                                onClick={() => toggleStep(index)}
                                className={`w-8 h-8 rounded-full flex items-center justify-center border transition-all duration-200 ${
                                    completedSteps.has(index)
                                        ? 'bg-gray-900 dark:bg-gray-100 border-transparent'
                                        : 'border-gray-300 dark:border-gray-500 hover:border-gray-400 dark:hover:border-gray-400'
                                }`}
                            >
                                {completedSteps.has(index) ? (
                                    <Check className="h-4 w-4 text-white dark:text-gray-900" />
                                ) : (
                                    <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                        {getStepNumbering(index)}
                                    </span>
                                )}
                            </button>
                        </div>

                        <div className="flex-grow space-y-1">
                            <div
                                className={`text-base leading-relaxed ${
                                    completedSteps.has(index)
                                        ? 'text-gray-400 line-through dark:text-gray-500'
                                        : 'text-gray-700 dark:text-gray-300'
                                }`}
                            >
                                {step}
                            </div>
                            {renderIngredientBadges(stepIngredients)}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
            <div className="p-4 border-b border-gray-200 dark:border-gray-700">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center gap-2">
                    Instructions
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        ({totalSteps} steps)
                    </span>
                </h3>
                {!shouldShowIngredients && (
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                        Note - complex recipes aren't always extracted accurately. Make sure to double-check the steps in the original recipe. I'm working on making it better, thank you for your support!
                    </p>
                )}
            </div>

            <div className="divide-y divide-gray-200 dark:divide-gray-700">
                {processedInstructions.data.map(([category, steps]) => (
                    <div key={category} className="border-b border-gray-200 dark:border-gray-700 last:border-b-0">
                        {processedInstructions.type === 'categorized' && (
                            <button
                                onClick={() => toggleCategory(category)}
                                className="w-full p-4 flex items-center justify-between text-left hover:bg-gray-50 dark:hover:bg-gray-700/50"
                            >
                                <span className="font-medium text-gray-900 dark:text-white">
                                    {category}
                                </span>
                                {expandedCategories.has(category) ? (
                                    <ChevronUp className="h-5 w-5 text-gray-500" />
                                ) : (
                                    <ChevronDown className="h-5 w-5 text-gray-500" />
                                )}
                            </button>
                        )}

                        {(processedInstructions.type === 'legacy' || expandedCategories.has(category)) && (
                            <div className="divide-y divide-gray-200 dark:divide-gray-700">
                                {steps.map(({ step, index }) => renderInstructionStep(step, index))}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {(completedSteps.size > 0 || activeStep !== null) && (
                <div className="p-4 bg-gray-50 dark:bg-gray-700/50 border-t border-gray-200 dark:border-gray-700">
                    <div className="flex justify-between items-center text-sm text-gray-500 dark:text-gray-400">
                        <span>
                            {completedSteps.size} of {totalSteps} steps completed
                        </span>
                        {completedSteps.size > 0 && (
                            <button
                                onClick={() => {
                                    setCompletedSteps(new Set());
                                    setActiveStep(null);
                                }}
                                className="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100 font-medium"
                            >
                                Reset Progress
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RecipeInstructions;