import React, { useContext } from 'react';
import { LogIn } from 'lucide-react';
import UserContext from './UserContext';

const LoginPrompt = () => {
    const { setShowGoogleLogin } = useContext(UserContext);

    const handleLoginClick = (e) => {
        e.preventDefault();
        localStorage.setItem('redirectAfterLogin', '/recipes');
        setShowGoogleLogin(true);
    };

    return (
        <div className="mb-6 p-4 rounded-lg border bg-blue-50 dark:bg-blue-900/20 border-blue-200 dark:border-blue-800 print:hidden">
            <div className="flex items-center gap-3">
                <LogIn className="h-5 w-5 text-blue-500 dark:text-blue-400 flex-shrink-0" />
                <p className="text-blue-700 dark:text-blue-300 text-sm">
                    Your recipe will be saved locally. To sync across devices, you can{' '}
                    <button
                        onClick={handleLoginClick}
                        className="underline underline-offset-2 font-medium hover:text-blue-600 dark:hover:text-blue-200">
                        sign in
                    </button>
                </p>
            </div>
        </div>
    );
};

export default LoginPrompt;