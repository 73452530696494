import React, { useState, useEffect, useMemo } from 'react';
import { Search, Filter, ChevronDown, Calendar, Clock, CheckSquare, Square } from 'lucide-react';
import axios from "axios";

const QuickDateSelector = ({ onDateChange }) => {
    const handleQuickSelect = (period) => {
        const today = new Date();
        const from = new Date();

        switch (period) {
            case 'week':
                from.setDate(today.getDate() - 7);
                break;
            case '2weeks':
                from.setDate(today.getDate() - 14);
                break;
            case 'month':
                from.setMonth(today.getMonth() - 1);
                break;
            case 'all':
                onDateChange({ from: null, to: null });
                return;
            default:
                return;
        }

        onDateChange({
            from: from.toISOString().split('T')[0],
            to: today.toISOString().split('T')[0]
        });
    };

    return (
        <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex flex-wrap gap-2">
                <button
                    onClick={() => handleQuickSelect('week')}
                    className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300"
                >
                    Last week
                </button>
                <button
                    onClick={() => handleQuickSelect('2weeks')}
                    className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300"
                >
                    2 weeks
                </button>
                <button
                    onClick={() => handleQuickSelect('month')}
                    className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300"
                >
                    Last month
                </button>
                <button
                    onClick={() => handleQuickSelect('all')}
                    className="px-3 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300"
                >
                    All time
                </button>
            </div>
            <div className="flex gap-2 items-center">
                <input
                    type="date"
                    className="px-3 py-1 text-sm border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-700 dark:text-white"
                    onChange={(e) => onDateChange(prev => ({ ...prev, from: e.target.value }))}
                />
                <span className="text-gray-500 dark:text-gray-400">to</span>
                <input
                    type="date"
                    className="px-3 py-1 text-sm border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-700 dark:text-white"
                    onChange={(e) => onDateChange(prev => ({ ...prev, to: e.target.value }))}
                />
            </div>
        </div>
    );
};

const RecipesPage = ({
                         imagesDomainPath = process.env.REACT_APP_DOMAIN || 'https://recipeextractor.com/'
                     }) => {
    const [recipes, setRecipes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [ingredientSearch, setIngredientSearch] = useState('');
    const [selectedIngredients, setSelectedIngredients] = useState(new Set());
    const [isLoading, setIsLoading] = useState(true);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [dateRange, setDateRange] = useState({ from: null, to: null });
    const [hoveredRecipe, setHoveredRecipe] = useState(null);
    const [sortOption, setSortOption] = useState('dateDesc');

    useEffect(() => {
        fetchRecipes();
    }, []);

    const fetchRecipes = async () => {
        try {
            const token = localStorage.getItem('recipeToken');
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/recipes`, config);
            setRecipes(response.data.recipes);
        } catch (error) {
            if (error.response?.status === 401) {
                localStorage.removeItem('recipeToken');
            }
            console.error('Error fetching recipes:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const ingredients = useMemo(() => {
        const uniqueIngredients = new Set();
        recipes.forEach(recipe => {
            recipe.ingredients?.forEach(ing => {
                if (ing?.ingredient) {  // Only add if ingredient exists
                    uniqueIngredients.add(ing.ingredient.toLowerCase());
                }
            });
        });
        return Array.from(uniqueIngredients)
            .sort()
            .filter(ingredient =>
                ingredient.toLowerCase().includes(ingredientSearch.toLowerCase())
            );
    }, [recipes, ingredientSearch]);

    const toggleIngredient = (ingredient) => {
        const newSelectedIngredients = new Set(selectedIngredients);
        if (newSelectedIngredients.has(ingredient)) {
            newSelectedIngredients.delete(ingredient);
        } else {
            newSelectedIngredients.add(ingredient);
        }
        setSelectedIngredients(newSelectedIngredients);
    };

    const searchInRecipe = (recipe, term) => {
        const searchTerm = term.toLowerCase();

        if (recipe.title.toLowerCase().includes(searchTerm)) return true;
        if (recipe.ingredients?.some(ing =>
            ing.ingredient.toLowerCase().includes(searchTerm) ||
            ing.special.toLowerCase().includes(searchTerm)
        )) return true;
        if (recipe.instructions?.some(instruction =>
            instruction.toLowerCase().includes(searchTerm)
        )) return true;

        return false;
    };

    const isWithinDateRange = (recipe) => {
        if (!dateRange.from && !dateRange.to) return true;

        const recipeDate = new Date(recipe.createdAt);
        const fromDate = dateRange.from ? new Date(dateRange.from) : null;
        const toDate = dateRange.to ? new Date(dateRange.to) : null;

        if (fromDate && toDate) {
            return recipeDate >= fromDate && recipeDate <= toDate;
        } else if (fromDate) {
            return recipeDate >= fromDate;
        } else if (toDate) {
            return recipeDate <= toDate;
        }

        return true;
    };

    const filteredRecipes = useMemo(() => {
        return recipes.filter(recipe => {
            const matchesSearch = searchTerm === '' || searchInRecipe(recipe, searchTerm);
            const matchesIngredients = selectedIngredients.size === 0 ||
                recipe.ingredients?.some(ing =>
                    ing?.ingredient && selectedIngredients.has(ing.ingredient.toLowerCase())
                );
            const matchesDateRange = isWithinDateRange(recipe);
            return matchesSearch && matchesIngredients && matchesDateRange;
        });
    }, [recipes, searchTerm, selectedIngredients, dateRange]);

    const sortedAndFilteredRecipes = useMemo(() => {
        let filtered = [...filteredRecipes];
        
        // Apply sorting
        switch (sortOption) {
            case 'dateAsc':
                filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                break;
            case 'dateDesc':
                filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                break;
            case 'titleAsc':
                filtered.sort((a, b) => a.title.localeCompare(b.title));
                break;
            case 'titleDesc':
                filtered.sort((a, b) => b.title.localeCompare(a.title));
                break;
            case 'timeAsc':
                filtered.sort((a, b) => (a.totalTime || 0) - (b.totalTime || 0));
                break;
            case 'timeDesc':
                filtered.sort((a, b) => (b.totalTime || 0) - (a.totalTime || 0));
                break;
            default:
                break;
        }
        
        return filtered;
    }, [filteredRecipes, sortOption]);

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const handleRecipeClick = (recipe, e) => {
        if (e.target.closest('.ingredient-tag')) {
            e.stopPropagation();
            return;
        }
        window.location.href = `/recipe/${recipe._id}`;
    };

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="flex flex-col space-y-4">
                    <h1 className="text-3xl font-bold text-gray-900 dark:text-white">My Recipes</h1>

                    <div className="flex flex-col gap-4 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                        <div className="flex flex-col sm:flex-row gap-4">
                            <div className="relative flex-1">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
                                <input
                                    type="text"
                                    placeholder="Search recipes..."
                                    className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div className="w-full md:w-48">
                                <select
                                    value={sortOption}
                                    onChange={(e) => setSortOption(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-700 dark:text-white"
                                >
                                    <option value="dateDesc">Newest First</option>
                                    <option value="dateAsc">Oldest First</option>
                                    <option value="titleAsc">Title A-Z</option>
                                    <option value="titleDesc">Title Z-A</option>
                                </select>
                            </div>
                            <div className="relative w-full sm:w-[240px]">
                                <button
                                    onClick={() => setIsSelectOpen(!isSelectOpen)}
                                    className="w-full px-4 py-2 text-left border border-gray-300 dark:border-gray-600 rounded-lg flex items-center justify-between bg-white dark:bg-gray-700 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                >
                  <span className="text-gray-700 dark:text-white">
                    {selectedIngredients.size === 0
                        ? 'Filter by ingredients'
                        : `${selectedIngredients.size} ingredients`}
                  </span>
                                    <ChevronDown className="h-4 w-4 text-gray-500" />
                                </button>
                                {isSelectOpen && (
                                    <div className="absolute z-10 w-64 mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg max-h-64 overflow-y-auto">
                                        <div className="p-2 border-b border-gray-200 dark:border-gray-600">
                                            <input
                                                type="text"
                                                placeholder="Search ingredients..."
                                                className="w-full px-3 py-1 text-sm border border-gray-300 dark:border-gray-600 rounded bg-gray-50 dark:bg-gray-800"
                                                value={ingredientSearch}
                                                onChange={(e) => setIngredientSearch(e.target.value)}
                                            />
                                        </div>
                                        {ingredients.map((ingredient) => (
                                            <button
                                                key={ingredient}
                                                className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-white flex items-center gap-2 text-sm"
                                                onClick={() => toggleIngredient(ingredient)}
                                            >
                                                {selectedIngredients.has(ingredient) ? (
                                                    <CheckSquare className="h-4 w-4" />
                                                ) : (
                                                    <Square className="h-4 w-4" />
                                                )}
                                                {ingredient}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <QuickDateSelector onDateChange={setDateRange} />
                    </div>

                    <div className="flex flex-wrap gap-2 min-h-8">
                        {Array.from(selectedIngredients).map((ingredient) => (
                            <span
                                key={ingredient}
                                className="px-1.5 py-0.5 text-xs bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100 rounded-full flex items-center gap-1"
                                onClick={() => toggleIngredient(ingredient)}
                                role="button"
                            >
                {ingredient}
                                <span className="cursor-pointer hover:text-blue-600 dark:hover:text-blue-300">×</span>
              </span>
                        ))}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {sortedAndFilteredRecipes.map((recipe) => (
                            <div
                                key={recipe._id}
                                className="group bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-all cursor-pointer relative"
                                onClick={(e) => handleRecipeClick(recipe, e)}
                                onMouseEnter={() => setHoveredRecipe(recipe._id)}
                                onMouseLeave={() => setHoveredRecipe(null)}
                                role="button"
                                aria-label={`View recipe: ${recipe.title}`}
                            >
                                {recipe.ogImage && (
                                    <div className="aspect-video w-full overflow-hidden rounded-t-lg relative">
                                        <img
                                            src={`${imagesDomainPath}/${recipe.ogImage.replace(/\\\\/g, '/')}`}
                                            alt={recipe.title}
                                            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                                        />
                                        <div className={`absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-opacity duration-300`} />
                                    </div>
                                )}
                                <div className="p-4">
                                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-2 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors">
                                        {recipe.title}
                                    </h2>
                                    <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400 mb-4">
                                        <Calendar className="h-4 w-4" />
                                        {formatDate(recipe.createdAt)}
                                    </div>
                                    <div className="flex flex-wrap gap-1.5 mb-4">
                                        {recipe.ingredients?.slice(0, 3).map((ing) => (
                                            <span
                                                key={ing._id}
                                                className="ingredient-tag px-1.5 py-0.5 text-xs bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-full"
                                            >
                        {ing.ingredient}
                      </span>
                                        ))}
                                        {recipe.ingredients?.length > 3 && (
                                            <span className="px-1.5 py-0.5 text-xs border border-gray-200 dark:border-gray-600 text-gray-500 dark:text-gray-400 rounded-full">
                        +{recipe.ingredients.length - 3}
                      </span>
                                        )}
                                    </div>
                                    <div className="flex justify-between text-sm text-gray-500 dark:text-gray-400">
                    <span className="flex items-center gap-1">
                      <Clock className="h-4 w-4" />
                        {recipe.servings && `Serves ${recipe.servings}`}
                    </span>
                                        <span>{recipe.domain}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {!isLoading && sortedAndFilteredRecipes.length === 0 && (
                        <div className="text-center py-12">
                            <h3 className="text-lg font-medium text-gray-900 dark:text-white">No recipes found</h3>
                            <p className="text-gray-500 dark:text-gray-400">Try adjusting your search or filter criteria</p>
                        </div>
                    )}

                    {isLoading && (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {[...Array(6)].map((_, index) => (
                                <div key={index} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
                                    <div className="aspect-video w-full bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse mb-4" />
                                    <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded w-3/4 animate-pulse mb-4" />
                                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2 animate-pulse mb-4" />
                                    <div className="flex gap-2 mb-4">
                                        {[...Array(3)].map((_, i) => (
                                            <div key={i} className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-16 animate-pulse" />
                                        ))}
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-20 animate-pulse" />
                                        <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-24 animate-pulse" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RecipesPage;